<template>
  <div>
    <div class="col-12 g" v-if="devices.length">
      <div class="alert alert-danger g" style="padding: 20px">
        <h5>هناك اجهزة بصمة غير متصلة وقد تؤثر على التقارير:</h5>
        <ul>
          <li v-for="(device, index) in devices" :key="index">
            اسم الجهاز: {{ device.title }}
            <br />
            الرقم التسلسلي: {{ device.serial_number }}
            <br />
            آخر نشاط: {{ device.last }}
          </li>
        </ul>
      </div>
    </div>
    <b-card title="📃 تقارير الحضور والغياب والتأخر">
      <div class="row">
        <div class="col-12 col-lg-4 c">
          <div class="form-group">
            <h5 for="">إختر التاريخ</h5>
            <input
              type="date"
              class="form-control form-control-lg"
              ref="date"
              id="date"
            />
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12 col-lg-6 c text-center">
          <button class="btn btn-lg btn-primary" @click="viewReport()">
            عرض التقرير الآن
          </button>
        </div>
      </div>
    </b-card>
    <b-card v-if="table">
      <div class="col-12 text-center g" v-if="loading">
        <img
          src="../../assets/images/loading.svg"
          style="width: 60px; height: 60px"
          alt=""
        />
        <br />
        جاري البحث..
      </div>
      <div class="col-12 g text-center" v-if="reports.length > 0">
        <button class="btn btn-primary" @click="print('all', true)">
          طباعة التقرير اليومي
        </button>
        &nbsp;
        <button class="btn btn-info" @click="print('all')">
          طباعة الجدول كاملاً
        </button>
        &nbsp;
        <button class="btn btn-success" @click="print('1')">
          طباعة الحضور
        </button>
        &nbsp;
        <button class="btn btn-warning" @click="print('2')">
          طباعة التأخر
        </button>
        &nbsp;
        <button class="btn btn-info" v-b-modal.send>
          ارسال الغياب والتأخر
        </button>
        &nbsp;
        <button class="btn btn-danger" @click="print('3')">طباعة الغياب</button>
        &nbsp;
        <button class="btn btn-secondary" @click="addLate()" v-b-modal.modal-6>اضافة التأخر لنور</button>
        <br />
        <div class="col-12 col-lg-6 c g text-center">
          <div class="form-group">
            <label for="">فرز بمجموعة الإعدادات</label>
            <select
              class="form-control"
              name=""
              v-model="selected_group"
              @change="ga()"
              id=""
            >
              <template v-for="group in groups">
                <option :value="group.code" :key="group.code">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div
        class="col-12 table-responsive"
        ref="table"
        id="table"
        v-if="reports.length > 0"
      >
        <table class="table table-hover table-striped">
          <thead>
            <th>م</th>
            <th>المعلم</th>
            <th class="dnone">التخصص</th>
            <th class="dnone">الدرجة</th>
            <th class="dnone">العمل الحالي</th>
            <th>الحالة</th>
            <th class="no">طريقة التحضير</th>
            <th>توقيت الحضور</th>
            <th>توقيت الإنصراف</th>
            <th class="no">خيارات</th>
          </thead>
          <tbody>
            <tr v-for="(report, index) in reports" v-bind:key="report._id">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ report.name }}
              </td>
              <td class="dnone">
                {{ report.ta5asos }}
              </td>
              <td class="dnone">
                {{ report.current_job }}
              </td>
              <td class="dnone">
                {{ report.degree }}
              </td>
              <td>
                <span class="badge badge-secondary" v-if="report.status == 0"
                  >خارج وقت الدوام</span
                >
                <span class="badge badge-success" v-if="report.status == 1"
                  >حاضر</span
                >
                <span class="badge badge-warning lated" v-if="report.status == 2"
                  >متأخر</span
                >
                <div v-if="report.status == 2">
                  متأخر: {{ report.late_time }} دقيقة ({{
                    (report.late_time / 60).toFixed(1)
                  }}
                  ساعة)
                </div>
                <span class="badge badge-danger" v-if="report.status == 3"
                  >غائب</span
                >
                <span class="badge badge-primary" v-if="report.status == 5"
                  >يوم إجازة (لم يتم الفحص فيه)</span
                >
                <span
                  v-if="allowedtoedit"
                  class="text-danger"
                  style="margin-right: 3px; font-size: 6px"
                  title="تعديل"
                  @click="
                    changeStatus(
                      report.number,
                      report.status,
                      report.time,
                      report.time ? true : false,
                      report._id,
                      report.late_time,
                      report.notes
                    )
                  "
                  ><feather-icon icon="ToolIcon" size="15"
                /></span>
                <span v-if="report.notes">
                  <span v-if="report.notes != '' && report.notes != null">
                    <br />
                    {{ report.notes }}
                  </span>
                </span>
              </td>
              <td class="no">
                <span class="badge badge-secondary" v-if="report.type == 1"
                  >يدوياً</span
                >
                <span class="badge badge-success" v-if="report.type == 2"
                  >بالباركود</span
                >
                <span class="badge badge-primary" v-if="report.type == 3"
                  >البصمة <br />
                  <br />
                  {{ report.fp }}
                </span>
              </td>
              <td>
                <strong>{{ report.time }}</strong>
                <br />
                <i>{{
                  report.htime
                    ? report.htime.year +
                      "/" +
                      report.htime.month +
                      "/" +
                      report.htime.day +
                      " " +
                      report.time.split(" ")[1]
                    : ""
                }}</i>
              </td>
              <td>
                <strong>{{ report.end ? report.end.time : "" }}</strong>
                <br />
                <i>{{
                  report.end
                    ? report.end.htime.year +
                      "/" +
                      report.end.htime.month +
                      "/" +
                      report.end.htime.day +
                      " " +
                      report.end.time.split(" ")[1]
                    : ""
                }}</i>
              </td>
              <td class="no">
                <!-- primary -->
                <b-dropdown text="خيارات" variant="primary">
                  <b-dropdown-item v-b-modal.modal-1 @click="details(report)">
                    <feather-icon icon="AlignRightIcon" size="20" />
                    عرض التفاصيل
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="ghiabAsk(report)"
                    v-if="report.status == '3'"
                  >
                    <feather-icon icon="InfoIcon" size="20" />
                    مسائلة غياب
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="lateAsk(report)"
                    v-if="report.status == '2'"
                  >
                    <feather-icon icon="InfoIcon" size="20" />
                    مسائلة تأخر
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <!-- basic modal -->
    <b-modal id="modal-6" hide-footer size="lg"><div style="display:none;" id="ff"></div>
      <div class="col-12 text-center g">
        <button class="btn btn-primary" @click="addLateNow()">
          اضافة التأخر الان
        </button>
      </div>
      <table class="table table-sm table-hover table-bordered">
        <thead>
          <th>
              المعلم
          </th>
          <th>
              النوع
          </th>
          <th>
              المدة
          </th>
        </thead>
        <tbody>
            <tr v-for="teacher in arr" :key="teacher.id">
                <td>
                    {{ teacher.name }}
                </td>
                <td>
                    <select v-model="teacher.type" style="border:none; width: 100%">
                        <option value="5">تأخير بدون عذر</option>
                        <option value="3">تأخير بعذر</option>
                    </select>
                </td>
                <td>
                    <span style="display: inline-block">
                        <input type="number" style="border:none; width: 100px" v-model="teacher.h" placeholder="ساعة">
                    </span>
                    <span style="display: inline-block">
                        <input type="number" style="border:none; width: 100px" v-model="teacher.m" placeholder="دقيقة">
                    </span>
                </td>
            </tr>
        </tbody>
      </table>
    </b-modal>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تفاصيل التقرير"
      ok-only
      ok-title="حسناً، شكراً لك"
    >
      <b-card-text>
        <div class="col-12 table-responsive">
          <table class="table table-bordered table-hover">
            <tbody>
              <tr>
                <td>الإسم</td>
                <td>{{ r.name }}</td>
              </tr>
              <tr>
                <td>رقم الهوية</td>
                <td>{{ r.number }}</td>
              </tr>
              <tr>
                <td>الحالة</td>
                <td>
                  <span class="badge badge-secondary" v-if="r.status == 0"
                    >خارج وقت الدوام</span
                  >
                  <span class="badge badge-success" v-if="r.status == 1"
                    >حاضر</span
                  >
                  <span class="badge badge-warning" v-if="r.status == 2"
                    >متأخر</span
                  >
                  <div v-if="r.status == 2">
                    متأخر: {{ r.late_time }} دقيقة ({{
                      (r.late_time / 60).toFixed(1)
                    }}
                    ساعة)
                  </div>
                  <span class="badge badge-danger" v-if="r.status == 3"
                    >غائب</span
                  >
                  <span class="badge badge-primary" v-if="r.status == 5"
                    >يوم إجازة (لم يتم الفحص فيه)</span
                  >
                </td>
              </tr>
              <tr>
                <td>طريقة الفحص</td>
                <td>
                  <span class="badge badge-secondary" v-if="r.type == 1"
                    >يدوياً</span
                  >
                  <span class="badge badge-success" v-if="r.type == 2"
                    >بالباركود</span
                  >
                  <span class="badge badge-primary" v-if="r.type == 3"
                    >البصمة <br /><br />
                    {{ r.fp }}</span
                  >
                </td>
              </tr>
              <tr>
                <td>توقيت التحضير</td>
                <td>
                  <strong>{{ r.time }}</strong>
                  <br />
                  <i>{{
                    r.htime
                      ? r.htime.year +
                        "/" +
                        r.htime.month +
                        "/" +
                        r.htime.day +
                        " " +
                        r.time.split(" ")[1]
                      : ""
                  }}</i>
                </td>
              </tr>
              <tr>
                <td>توقيت الإنصراف</td>
                <td>
                  <strong>{{ r.end ? r.end.time : "" }}</strong>
                  <br />
                  <i>{{
                    r.end
                      ? r.end.htime.year +
                        "/" +
                        r.end.htime.month +
                        "/" +
                        r.end.htime.day +
                        " " +
                        r.end.time.split(" ")[1]
                      : ""
                  }}</i>
                </td>
              </tr>
              <tr>
                <td>توقيت رفع التقرير للنظام</td>
                <td>{{ r.back_time }}</td>
              </tr>
              <tr>
                <td>اليوم</td>
                <td>{{ r.date }}</td>
              </tr>
              <tr>
                <td>الإعدادات عند الفحص</td>
                <td v-if="r.settings">
                  <li>
                    توقيت الحضور:
                    {{ r.settings.start_date }}
                  </li>
                  <li>
                    توقيت التأخر:
                    {{ r.settings.late_date }}
                  </li>
                  <li>
                    توقيت الغياب:
                    {{ r.settings.ghiab_date }}
                  </li>
                  <li>
                    توقيت الإنصراف:
                    {{ r.settings.end_date }}
                  </li>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="modal-2" title="تعديل التقرير" hide-footer>
      <b-card-text>
        <div>
          <div class="alert alert-danger g text-center">
            نحن نخلي مسؤوليتنا في حالة العبث في التقارير.
          </div>
          <div class="form-group">
            <label for="">اختر التوقيت</label>
            <input type="time" class="form-control" v-model="ttime" />
          </div>
          <div class="form-group">
            <label for="">الحالة</label>
            <select class="form-control" v-model="sstatus">
              <option value="0">خارج وقت الدوام</option>
              <option value="1">حضور</option>
              <option value="2">تأخر</option>
              <option value="3">غياب</option>
              <option value="4">انصراف</option>
            </select>
          </div>
          <div class="form-group" v-if="sstatus == 2">
            <label for="">دقائق التأخر</label>
            <input type="number" class="form-control" v-model="mins" />
          </div>
          <div class="form-group">
            <label for="">ملاحظات</label>
            <input
              type="text"
              class="form-control"
              v-model="notes"
              placeholder="اكتب الملاحظات هنا"
            />
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="changeStatusNow()">
              تعديل التقرير الآن
            </button>
          </div>
        </div>
      </b-card-text>
    </b-modal>
    <!-- basic modal -->
    <b-modal id="send" title="ارسال" hide-footer size="lg">
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" v-model="selected_statuses" value="2">
          ارسال التاخر
        </label>
      </div>
      <div class="form-check">
        <label class="form-check-label">
          <input type="checkbox" class="form-check-input" v-model="selected_statuses" value="3">
          ارسال الغياب
        </label>
      </div>
      <hr>
      <div class="form-group">
        <label for="">الرسالة</label>
        <textarea class="form-control" v-model="message" rows="3"></textarea>
      </div>
      <hr>
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click="sendNow()">
          إرسال الرسالة SMS
        </button>
        &nbsp;
        <button class="btn btn-success" @click="sendNowWA()">
          إرسال الرسالة واتسآب
        </button>
        <br /><br />
        <small>
          برجاء التأكد من تثبيت اداة الإرسال
          <a
            href="https://chrome.google.com/webstore/detail/%D8%AE%D8%AF%D9%85%D8%A7%D8%AA-%D8%A7%D8%B6%D8%A7%D9%81%D9%8A%D8%A9-%D8%A8%D8%B1%D9%85%D8%AC%D8%A9-%D8%AF%D9%88%D8%AA-%D9%83%D9%88/klbkaeaebhjkhmhilmbkfdcjdlinjpco"
            target="_blank"
            >من هنا</a
          >
        </small>
      </div>
      <hr>
      <div class="table-responsive">
        <table class="table table-hover table-sm table-bordered">
          <thead>
            <th>
              المعلم
            </th>
            <th>
              الرسالة
            </th>
          </thead>
          <tbody>
            <template  v-for="(report) in reports">
              <tr v-if="selected_statuses.includes(report.status.toString())" v-bind:key="report._id">
                <td>
                  {{ report.name }}
                </td>
                <td>
                  <textarea style="width: 100%; border: none;" rows="3" readonly>{{ generateMessage(report) }}</textarea>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
var moment = require("moment-hijri");

import {
  BCard,
  BCardText,
  BLink,
  BDropdown,
  VBModal,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      groups: [],
      viewed: [],
      shifts: {},
      allowedtoedit:
        JSON.parse(localStorage.getItem("per"))?.includes(
          "teachers_reports_edit"
        ) || !localStorage.getItem("per")
          ? true
          : false,
      table: false,
      loading: false,
      reports: [],
      r: {},
      info: {},
      arr: [],
      number: null,
      ttime: null,
      sstatus: null,
      ttype: null,
      mins: 0,
      devices: [],
      selected_group: null,
      notes: null,
      message: `المعلم {name}
{status} اليوم بتاريخ {date}`,
      selected_statuses: ["2","3"]
    };
  },
  methods: {
    viewReport() {
      if (this.$refs.date.value == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء اختيار التاريخ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        var g = this;
        g.viewed = [];
        this.table = true;
        this.loading = true;
        axios
          .post(localStorage.getItem("api") + "/reports/index", {
            jwt: localStorage.getItem("jwt"),
            date: this.$refs.date.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              var arr = [];
              r.data.response.forEach(function (a) {
                if (a.status != 4 && !g.viewed.includes(a.number)) {
                  g.viewed.push(a.number);
                  arr.push(a);
                }
              });
              g.reports = arr;
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    print(i, daily = false) {
      var g = this;
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              border: 1px solid #ccc;
          }
          .no, svg ${!daily ? ",.dnone" : ""}{
            display: none;
          }
        `);
      printWindow.document.designMode = "on";
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      moment.locale("ar-SA");
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ];
      var d = new Date(g.$refs.date.value);
      var dayname = days[d.getDay()];
      var date =
        moment(this.$refs.date.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      if (i == "all") {
        var title = "حضور وتأخر وغياب";
      }
      if (i == "1") {
        var title = "حضور";
      }
      if (i == "2") {
        var title = "تأخر";
      }
      if (i == "3") {
        var title = "غياب";
      }
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; padding: 20px;'>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            وزارة التعليم
            <br><br>
            ${g.info.school_title}
          </h5>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 50%'>
          <br>
          <h2>تقرير ${title} الموظفين</h2>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          اليوم: ${dayname}
          <br><br>
          التاريخ: ${date}
          </h3>
        </div>
      </div>
      `);
      //var divContents = this.$refs.table.innerHTML;
      var divContents = document.getElementById("table")?.innerHTML;
      printWindow.document.write(divContents);
      printWindow.document.querySelectorAll("tr").forEach(function (a) {
        if (i != "all" && !a.innerHTML.includes("طريقة التحضير")) {
          if (i == "1") {
            if (!a.innerHTML.includes("حاضر")) {
              a.style.display = "none";
            }
          }
          if (i == "2") {
            if (!a.innerHTML.includes("متأخر")) {
              a.style.display = "none";
            }
          }
          if (i == "3" || i == 3) {
            if (!a.innerHTML.includes("غائب")) {
              a.style.display = "none";
            }
          }
        }
      });
      var teachersx = "";
      if(g.shifts){
      var daysx = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        if(g.shifts[daysx[d.getDay()]]){
          if(g.shifts[daysx[d.getDay()]].length){
            g.shifts[daysx[d.getDay()]].forEach(function(v){
              teachersx = teachersx + v + '<br>'
            })
          }
        }
      }
      printWindow.document.write(
        `<br></center>${
          daily
            ? `<h3 style='float: right; position: relative; right: 30px'>المعلم المناوب<br>${teachersx}</h3><h3 style='float: left; position: relative; left: 30px'>المشرف الزائر</h3>`
            : ""
        }</body>`
      );

      printWindow.document.write("</html>");
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 1000);
    },
    
    sendNow() {
      var _g = this;
      if (
        confirm(
          `متاكد من الارسال؟`
        )
      ) {
        var arr = [];
         var xx = "", g = this;
          this.reports.forEach(function(report){
            if(g.selected_statuses.includes(report.status?.toString())){
              arr.push({
                phone: report.phone,
                message: g.generateMessage(report)
              })
            }
          })
        axios
          .post(localStorage.getItem("api") + "/teachers/send", {
            jwt: localStorage.getItem("jwt"),
            data: JSON.stringify(arr),
          })
          .then(function (r) {
            if (r.data.status == 100) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم إرسال الرسالة بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    "فشل إرسال الرسالة، برجاء الذهاب لارشيف الرسائل لمعرفة المشكلة",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    sendNowWA() {
      var xx = "", g = this;
      this.reports.forEach(function(report){
        if(g.selected_statuses.includes(report.status?.toString())){
          xx = xx + `<tr>
          <td>${report.phone}</td>
          <td><textarea>${g.generateMessage(report)}</textarea></td>
        </tr>`
        }
      })
      document.getElementById("brmja-messages-table").innerHTML = xx;
      document.getElementById("brmja-send").click();
    },
    details(report) {
      this.r = report;
    },
    ghiabAsk(report) {
      var _g = this;
      _g.$router.push(
        "/reports/ask-ghiab/" +
          report.date +
          "/" +
          report.number +
          "/" +
          report.teacher_id
      );
    },
    lateAsk(report) {
      var _g = this;
      _g.$router.push(
        "/reports/ask-late/" +
          report.date +
          "/" +
          report.number +
          "/" +
          report.teacher_id +
          "/" +
          report.late_time +
          "/" +
          report.time
      );
    },
    changeStatus(number, s, t, ta, d, m, q) {
      this.number = number;
      this.$bvModal.show("modal-2");
      this.ttime = t;
      this.sstatus = s;
      this.ttype = ta;
      this.iid = d;
      this.mins = m;
      this.notes = q;
    },
    changeStatusNow() {
      if (!this.ttime) {
        alert("برجاء اختيار التوقيت");
      } else {
        if (!this.sstatus) {
          alert("برجاء اختيار الحالة");
        } else {
          var _g = this;
          axios
            .post(localStorage.getItem("api") + "/reports/change-status", {
              jwt: localStorage.getItem("jwt"),
              number: this.number,
              status: this.sstatus,
              time: this.ttime,
              type: this.ttype,
              date: this.$refs.date.value,
              id: this.iid,
              mins: this.mins,
              notes: this.notes,
            })
            .then(function (r) {
              if (r.data.status == 200) {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "حدث خطأ JWT",
                    icon: "WarningIcon",
                    variant: "danger",
                  },
                });
              } else {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "تم تعديل التقرير بنجاح",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                _g.viewReport();
              }
            })
            .catch(function () {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    },
    ga() {
      var arr = [];
      if (this.$refs.date.value == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء اختيار التاريخ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        var g = this;
        this.table = true;
        this.loading = true;
        axios
          .post(localStorage.getItem("api") + "/reports/index", {
            jwt: localStorage.getItem("jwt"),
            date: this.$refs.date.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              r.data.response.forEach(function (a) {
                if (a.settings.code == g.selected_group) {
                  arr.push(a);
                }
              });
              g.reports = arr;
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return {
        m: rminutes,
        h: rhours
      }
    },
    addLate(){
      var g = this;
      var arr = []
      g.reports.forEach(function(a){
        if(a.status == 2){
          arr.push({
            name: a.name,
            type: '5',
            h: g.timeConvert(a.late_time).h,
            m: g.timeConvert(a.late_time).m,
          })
        }
      })
      g.arr = arr;
    },
    addLateNow(){
      var g = this;
      document.getElementById('ff').innerHTML = `
      <form target='_blank' action='https://tahdir.net/add-late-teachers' id='ccaa' method='post'>
        <textarea name='arr'>${JSON.stringify(g.arr)}</textarea>
      </form>
      `;
      document.getElementById('ccaa').submit()
    },
    generateMessage(report){
      var msg = this.message;
      msg = msg.replace("{name}", report.name);
      msg = msg.replace("{date}", report.date);
      msg = msg.replace("{status}", report.status?.toString().replace("2", "متاخر").replace("3", "غائب"));
      return msg;
    }
  },
  created() {
    var _g = this, g = this;
    
      var daysx = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      axios
      .post(localStorage.getItem("api") + "/teachers/shifts", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          try{
            if(r.data.response){
                g.shifts = JSON.parse(r.data.response);
            }
          }catch{
            daysx.forEach(function(a){
                g.shifts[a] = [];
            })
          }
        }
      })
      .catch(function () {
      });
    axios
      .post(localStorage.getItem("api") + "/teachers/groups", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.groups = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    axios
      .post(localStorage.getItem("api") + "/teachers/info", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.info = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    axios
      .post(localStorage.getItem("api") + "/teachers/devices", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (data) {
        _g.devices = data.data.response;
        if (data.data.status == 200) {
          alert("حدث مشكلة اثناء الاتصال بالخادم.");
        }

        _g.$refs.date.value = new Date().toISOString().split("T")[0];
      })
      .catch(function (error) {
        alert("حدث مشكلة اثناء الاتصال بالخادم");
      });
  },

};
</script>

<style>
.dnone {
  display: none;
}
</style>
